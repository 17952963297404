import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputEmail from 'Login/InputEmail/InputEmail';
import { useRealmApp } from 'App/RealmAppProvider';
import Loader from 'Loader/Loader';
import {
  Form, Button, Box, Heading
} from 'react-bulma-components';
import { Link, useHistory } from 'react-router-dom';

/**
 * ForgotPasswordBox Component - to handle sending password reset email.
 */
const ForgotPasswordBox = ({
  backgroundColor,
  title,
  setShowMsgBox,
  setMsgBox
}) => {
  const app = useRealmApp();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showLoader, setShowLoader] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleSubmit = async (event) => {
    setErrorMsg('');
    setShowLoader(true);
    event.preventDefault();
    try {
      // eslint-disable-next-line max-len
      await app.emailPasswordAuth.callResetPasswordFunction(email.toLowerCase(), 'govern-with-forgot-password-process');
      setMsgBox({
        title: 'Email Sent',
        msg: `A reset email has been sent to ${email} inbox.`
      });
      setErrorMsg('');
      setShowMsgBox(true);
      setShowLoader(false);
      history.push('/');
    } catch (err) {
      setShowLoader(false);
      setErrorMsg(err.error);
    }
  };

  return (
    <>
      <Loader msg="Sending Email..." showLoader={showLoader} />
      <Box
        style={backgroundColor && {
          backgroundColor
        }}
      >
        <Heading>
          {title}
        </Heading>
        <Heading subtitle>
          A password reset email will be sent to the follow email:
        </Heading>
        <form onSubmit={(event) => handleSubmit(event)}>
          <InputEmail value={email} handleChange={handleChange} />
          <Form.Help className="LoginBox-error-div has-text-error">
            {errorMsg}
          </Form.Help>
          <Form.Field>
            <Form.Control>
              <Button.Group align="right">
                <Button
                  renderAs={Link}
                  to="/"
                  type="button"
                  color="standout"
                  inverted
                  className="LoginBox-inverted-btn"
                  onClick={() => setErrorMsg('')}
                >
                  Cancel
                </Button>
                <Button
                  color="standout"
                  submit
                >
                  SEND
                </Button>
              </Button.Group>
            </Form.Control>
          </Form.Field>
        </form>
      </Box>
    </>
  );
};

export default ForgotPasswordBox;

ForgotPasswordBox.propTypes = {
  /**
   * What background color to use?
   */
  backgroundColor: PropTypes.string,
  /**
   * Title text for component.
   */
  title: PropTypes.string,
  setShowMsgBox: PropTypes.func,
  setMsgBox: PropTypes.func
};

ForgotPasswordBox.defaultProps = {
  backgroundColor: '#e9e9ea',
  title: 'Forgot Password?',
  setShowMsgBox: undefined,
  setMsgBox: undefined
};
