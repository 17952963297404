/*
* Develop Realm App ResultsKey
 */
// const APP_ID = 'gw-app-v1-sdggb';

/*
* UAT Realm App ResultsKey
 */
// const APP_ID = 'uat-governwith-plbxw';

/*
* Live Realm App ResultsKey.
 */
const APP_ID = 'governwith-npcvn';

/*
* Cam Realm App ResultsKey
 */
// const APP_ID = 'gw-app-v1-sdggb';

export default APP_ID;
